<template>
  <div>
    <ReportTable
      v-loading="loading"
      :type="type"
      :title="title"
      :fields="fields"
      :items="items"
      :pageTotal="pageTotal || 0"
      :currentPage="currentPage"
      :rows="rows"
      :path="path"
      :excel="true"
      :showHKSelect="true"
      :returnData="returnData"
      @handleSearch="handleSearch"
      :showSearchInput="true"
      @searchChange="searchChange"
      :placeholderVal="placeholderVal"
      :showExportText.sync="showExportText"
      @handleOptionChange="handleOptionChange"
      @exportToExcel="excelExport()"
    />
  </div>
</template>
<script>
import ReportTable from "./components/reportTable.vue";
import { getReportYearVersionDetail } from "@/api/specialRecord";
//import XLSX from 'xlsx'
import XLSX from "xlsx-js-style";
export default {
  name: "ReportVersion",
  components: {
    ReportTable
  },
  data() {
    return {
      showExportText: false,
      placeholderVal: "Search Film Name",
      searchVal: "",
      loading: false,
      type: "version",
      title: "22.1 B.O. Report For the year ( By Version )",
      currentPage: 1,
      selectDropdown: true,
      returnData: "",
      year: "",
      area: "",
      path: "report-year-version",
      fields: [
        {
          key: "name_en",
          label: "Title(Eng)",
          sortable: true
        },
        {
          key: "name_ch",
          label: "Title(Ch)",
          sortable: true
        },
        {
          key: "source",
          label: "Production Country",
          sortable: true
        },
        {
          key: "date_on",
          label: "Date on",
          sortable: true
        },
        {
          key: "date_release",
          label: "Date release",
          sortable: true
        },
        {
          key: "date_off",
          label: "Date off",
          sortable: true
        },
        {
          key: "days",
          label: "Days",
          sortable: true
        },
        {
          key: "accum_bo",
          label: "Accum B.O.",
          sortable: true
        }
      ],
      items: [],
      pageTotal: 10,
      perPage: 10,
      rows: 10000,
      search: "",
      startDate: "",
      endDate: "",
      startPeriod: "",
      endPeriod: "",
      exportData: [],
      countryVal: ""
    };
  },
  methods: {
    searchChange(val) {
      this.searchVal = val;
    },
    handleOptionChange(val) {
      console.log(val);
      this.countryVal = val;
      // this.getReportDetailed()
    },
    handleSearch() {
      this.currentPage = 1;
      this.getReportDetailed();
    },
    exportExcel() {
      console.log(this.exportData);
      //tabel list
      const header = [
        "Title(Eng.)",
        "Title(Chi.)",
        "Production Country",
        "Version",
        "Date On",
        "Date Release",
        "Date Off",
        "Days",
        "Version B.O.",
        "Accom. B.O."
      ];
      const dataList = [];
      this.exportData.map((item) => {
        const list = [];
        list.push(
          item.title_eng,
          item.title_chi,
          item.country,
          item.version,
          this.excelDateFormat(item.date_on),
          this.excelDateFormat(item.date_release),
          this.dateOffFormat(item.date_off, item.date_release),
          Number(item.days),
          Number(item.version_bo),
          Number(item.accum_bo)
        );
        dataList.push(list);
      });
      dataList.unshift(header);
      console.log(dataList);
      const numFmt = header.slice(7, 10);
      const numList = [];
      numFmt.map((key) => {
        numList.push({
          title: this.getAlphabetLetter(header.indexOf(key)),
          index: 2
        });
      });
      const excelInfo = {
        type: "22.1",
        merge: false,
        setDateValue: false,
        data: this.exportData,
        dateCol: [],
        fileName: `B.O.Movie_Report-${this.year}.xlsx`,
        header,
        excelData: dataList,
        numFmt: true,
        numFmtList: numList,
        hasTotal: false
      };
      return excelInfo;
    },
    getReportDetailed() {
      this.loading = true;
      const data = `year=${this.year}&area=${this.area}&country=${this.countryVal}&search=${this.searchVal}`;
      console.log(data);
      getReportYearVersionDetail(data).then((res) => {
        const arr = [];
        this.items = arr;
        res.data.forEach((el) => {
          const obj = new Object();
          obj.id = el.id;
          obj.name_ch = el.film_master.name_chinese;
          obj.name_en = el.film_master.name_english;
          obj.source = el.film_master.country;
          obj.date_on = el.film.date_on;
          obj.date_release = el.film.date_release;
          obj.date_off =
            el.film.date_off === "0001-01-01" ? "上映中" : el.film.date_off;
          obj.days = el.days || "-";
          obj.accum_bo = el.tg;
          obj._rowVariant =
            new Date(el.film.date_on) < new Date(el.film.date_release)
              ? "danger"
              : "";
          arr.push(obj);
        });
        this.items = arr;
        this.loading = false;
      });
    }
  },
  mounted() {
    if (this.year) {
      this.getReportDetailed();
    }
  },
  created() {
    this.returnData = {
      data: this.$route.query.date
    };
    this.year = this.$route.query.date;
    this.area = this.$route.query.area || "";
  }
};
</script>
